<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card title="Shipping Record" icon="mdi-account">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" lg="3" md="6">
              <v-select
                v-model="shippingStatus"
                :items="All"
                :item-text="(item) => item.text"
                :item-value="(item) => item.value"
                :label="$vuetify.lang.t('$vuetify.status')"
                prepend-inner-icon="mdi-state-machine"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :nudge-top="30"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="
                      $vuetify.lang.t('$vuetify.startDate') +
                      ' ~ ' +
                      $vuetify.lang.t('$vuetify.endDate')
                    "
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  />
                </template>

                <v-date-picker
                  v-model="dates"
                  range
                  @change="dateChange"
                  @input="dates.length == 2 ? (menu = false) : true"
                  class="ma-0 pa-0"
                  :locale="
                    this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'
                  "
                />
              </v-menu>
            </v-col>

            <v-col cols="12" lg="3" md="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                prepend-inner-icon="mdi-magnify"
                v-model="search"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="3"
              class="d-flex justify-end align-center"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              <v-btn
                color="green"
                class="ma-1"
                elevation="2"
                @click="handleExport"
              >
                <v-icon left>mdi-file-export</v-icon>
                {{ $vuetify.lang.t("$vuetify.export") }}
              </v-btn>

              <v-btn
                color="green"
                class="ma-1"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-end align-center" v-else>
              <v-spacer />

              <v-btn
                color="green"
                class="ma-1"
                elevation="2"
                @click="handleExport"
              >
                <v-icon>mdi-file-export</v-icon>
              </v-btn>

              <v-btn
                color="green"
                class="ma-1"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
                v-on:viewitem="handleViewDialog"
                v-on:arrived="handleArrviedDialog"
                v-on:freight="handleFreightDialog"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <ShippingCRUD
      :dialog.sync="dialog"
      @handleData="handleShipping"
      :SelectedOrder.sync="SelectedOrder"
      v-on:selectOrder="handleOrder"
      :orderId.sync="orderId"
      :myObj.sync="obj"
      :isBtnSearchOrder.sync="isBtnSearchOrder"
      :ship_way.sync="ship_way"
    />

    <SelectOrder
      :dialogOrder.sync="dialogOrder"
      @getselectedOrder="handleSelectOrder"
    />

    <DialogDelete
      :dialogDelete.sync="dialogDelete"
      :deleteObj="deleteObj"
      @handleDelete="handleDeleteConfirm"
    />

    <ViewDialog :dialogView.sync="dialogView" :myObj.sync="obj" />

    <ViewArrived
      @handleData="handleArrived"
      :dialogArrived.sync="dialogArrived"
      :myObj.sync="obj"
    />

    <ViewFreight
      @handleData="handleFreight"
      :dialogfreight.sync="dialogfreight"
      :myObj.sync="obj"
    />
  </v-container>
</template>

<script>
const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};
import { json2excel } from "js2excel";
import {
  listOrderSend,
  delOrderSend,
  upsertOrderSend,
  customerAff,
  orderSendShipping,
} from "@/api/order";
import { baseDataList } from "@/api/system";

const newObj = () => {
  return {
    id: "",
    order_info: {
      address: "",
      contact: "",
      order_id: "",
      po: "",
      tel: "",
    },
    user_info: {
      mobile: "",
      name: "",
      user_id: "",
    },
    customer_info: {
      company: "",
      contact: "",
      customer_id: "",
      tel: "",
    },
    send_goods: [
      {
        id: "",
        nick_name: "",
        number: 0,
        net_weight: 0,
        gross_weight: 0,
        name: "",
        order_number: 0,
        unit: "",
      },
    ],
    shipping_cost: 0,
    shipping_cost1: 0,
    shipping_cost2: 0,
    send_date: "",
    send_way: "",
    plan_receive: "",
    plan_receive_r1: "",
    plan_receive_r2: "",
    real_receive: "",
    status: 0,
    gen_time: "",
    modify_time: "",
    attach: [],
    note: "",
    ship_for: "",
    carton_dimension: "",
    carton_num: "",
  };
};

const newDelete = () => {
  return {
    id: "",
    order_info: {
      address: "",
      contact: "",
      order_id: "",
      po: "",
      tel: "",
    },
    user_info: {
      mobile: "",
      name: "",
      user_id: "",
    },
    customer_info: {
      company: "",
      contact: "",
      customer_id: "",
      tel: "",
    },
    send_goods: [
      {
        id: "",
        nick_name: "",
        number: 0,
        net_weight: 0,
        gross_weight: 0,
        name: "",
        order_number: 0,
        unit: "",
      },
    ],
    shipping_cost: 0,
    shipping_cost1: 0,
    shipping_cost2: 0,
    send_date: "",
    send_way: "",
    plan_receive: "",
    plan_receive_r1: "",
    plan_receive_r2: "",
    real_receive: "",
    status: 0,
    gen_time: "",
    modify_time: "",
    attach: [],
    note: "",
    ship_for: "",
    carton_dimension: "",
    carton_num: "",
  };
};

const newUpData = () => {
  return {
    carton_num: "",
    customer_id: "",
    id: "",
    note: "",
    order_id: "",
    plan_receive: "",
    plan_receive_r1: "",
    plan_receive_r2: "",
    real_receive: "",
    send_date: "",
    send_goods: "",
    send_way: "",
    ship_for: "",
    shipping_cost: 0,
    shipping_cost1: 0,
    customer_info: "",
  };
};

const newYunfei = () => {
  return {
    id: "",
    shipping_cost1: 0, // 境内运费
    shipping_cost2: 0, // 境外运费
    shipping_cost: 0, // 总运费（自动计算）
  };
};

const newSearch = () => {
  return {
    status: -1, // 状态（0 未处理，1已结案）
    order_id: "",
    customer_id: "",
    skip: 0,
    bt: "",
    et: "",
    limit: 500000,
    opt: "",
  };
};

const newOrderSearch = () => {
  return {
    status: -100,
    customer_id: "",
    po: "",
    skip: 0,
    limit: 10,
  };
};

export default {
  name: "ShippingRecord",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    ShippingCRUD: () => import("@/components/orders/shippingCRUD"),
    SelectOrder: () => import("@/components/orders/selectOrder"),
    PageNavigation: () => import("@/components/pageNavigation"),
    DialogDelete: () => import("@/components/deleteDialog"),
    ViewDialog: () => import("@/components/orders/shippingRecordView"),
    ViewArrived: () => import("@/components/orders/shippingRecordArrived"),
    ViewFreight: () => import("@/components/orders/shippingRecordFreight"),
  },
  data() {
    return {
      dates: [],
      menu: false,
      dialog: false,
      dialogOrder: false,
      dialogDelete: false,
      dialogView: false,
      dialogArrived: false,
      dialogfreight: false,
      isBtnSearchOrder: false,
      shippingStatus: "",
      search: "",
      orderId: "",
      commodity: "",
      SelectedOrder: {},
      obj: newObj(),
      deleteObj: newDelete(),
      updataData: newUpData(),
      yunfeiForm: newYunfei(),
      searchData: newSearch(),
      orderSearch: newOrderSearch(),
      datas: [],
      ship_way: [],
      cloneship_way: [],
      listOrder: [],
      All: [
        { text: "All", value: -1 },
        { text: "Floating Cargo", value: 0 },
        { text: "Have Arrived", value: 1 },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.order"),
          value: "order_info.po",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.customerordernumber"),
          width: "150",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Delivered"),
          value: "send_goods[0].name",
          width: "400",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.productUnit"),
          value: "send_goods[0].order_number",
          width: "100",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.consignor"),
          value: "user_info.name",
          width: "80",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.client"),
          value: "customer_info.contact",
          width: "80",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.plannedArrival"),
          value: "plan_receive",
          align: "center",
          width: "120",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.plannedArrivalR1"),
          value: "plan_receive_r1",
          align: "center",
          width: "120",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.plannedArrivalR2"),
          value: "plan_receive_r2",
          width: "120",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.dateOfDelivery"),
          value: "send_date",
          width: "120",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actualArrival"),
          value: "real_receive",
          width: "160",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.remarks"),
          value: "note",
          width: "180",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "arrivalGood",
          sortable: false,
          align: "center",
          width: "130",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.orderMgr") },
        { text: this.$vuetify.lang.t("$vuetify.deliveryDiscipline") },
      ],
    };
  },
  methods: {
    getData() {
      this.ship_way = [];
      this.cloneship_way = [];

      listOrderSend(this.searchData)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];

            this.datas.forEach((p, k) => {
              p.itemNo = k + 1;
              p.po = p.order_info.po;
            });
          } else {
            this.$toast.info("Not Data Found", { timeout: 2000 });
            this.datas = [];
          }
        })
        .catch((err) => console.log(err));

      baseDataList()
        .then((data) => {
          if (data.items != null) {
            this.cloneship_way = [...data.items];
            data.items.forEach((p) => this.ship_way.push(p.name));
          } else {
            this.cloneship_way = [];
          }
        })
        .catch((err) => console.log(err));
    },
    handleDialog() {
      this.dialog = true;
      this.obj = {};
      this.isBtnSearchOrder = true;
      this.SelectedOrder = {};
    },
    handleOrder() {
      this.dialogOrder = true;
    },
    handleExport() {
      let data = this.setData();
      json2excel({
        data,
        name: "Shipping Record",
        formateDate: "yyyy/mm/dd",
      });
      this.$toast.success("Downloaded", { timeout: 1500 });
    },
    setData() {
      var data = [];
      for (var i = 0; i < this.datas.length; i++) {
        var tempObj = {};
        this.$set(tempObj, "No", this.datas[i].itemNo);
        this.$set(tempObj, "Order", this.datas[i].po);
        this.$set(tempObj, "Delivered", this.datas[i].send_goods[0].name);
        this.$set(
          tempObj,
          "Prodcut Unit",
          this.datas[i].send_goods[0].order_number
        );
        this.$set(tempObj, "Sender", this.datas[i].user_info.name);
        this.$set(tempObj, "Customer", this.datas[i].customer_info.contact);
        this.$set(tempObj, "Est Arv Time", this.datas[i].plan_receive);
        this.$set(tempObj, "Est Arv Time 1", this.datas[i].plan_receive_r1);
        this.$set(tempObj, "Est Arv Time 2", this.datas[i].plan_receive_r2);
        this.$set(tempObj, "Delivery Date", this.datas[i].send_date);
        this.$set(tempObj, "Actual Arv Time", this.datas[i].real_receive);
        this.$set(tempObj, "Ship For", this.datas[i].ship_for);
        this.$set(tempObj, "Domestic Freight", this.datas[i].shipping_cost1);
        this.$set(tempObj, "Overseas Freight", this.datas[i].shipping_cost2);
        this.$set(
          tempObj,
          "Total Freight",
          Number(this.datas[i].shipping_cost1) +
            Number(this.datas[i].shipping_cost2)
        );
        data.push(tempObj);
      }
      return data;
    },
    handleShipping(obj) {
      this.dialog = false;

      upsertOrderSend(obj)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("New Shipping Err ", err));
    },
    handleSelectOrder(obj) {
      this.orderId = obj[0].po;
      this.obj = obj[0];
      this.SelectedOrder = obj[0];
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
        this.obj = renameKey(this.obj, "send_goods", "products");

        var completeData = Object.assign({}, this.obj, this.SelectedOrder);
        this.SelectedOrder = completeData;
        this.isBtnSearchOrder = false;

        for (var i = 0; i < this.cloneship_way.length; i++) {
          if (this.cloneship_way[i].code === this.SelectedOrder.send_way) {
            var shipname = this.cloneship_way[i].name;
            this.SelectedOrder.send_way = shipname;
          }
        }
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      delOrderSend(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
          this.$toast.success("Shipping Record Deleted");
        })
        .catch((err) => console.log("Procurement Subject Delete Error", err));
    },
    handleViewDialog(obj) {
      this.dialogView = true;
      this.obj = obj;
      console.log(obj);
    },
    handleArrived(obj) {
      this.dialogArrived = false;
      customerAff(obj.id, obj.real_receive)
        .then(() => {
          this.getData();
          this.$toast.success("Operation Sucessful");
        })
        .catch((err) => {
          console.log("Real Arrived:  ", err);
          this.$toast.error("Read Arrived Error");
        });
    },
    handleArrviedDialog(obj) {
      this.dialogArrived = true;
      this.obj = obj;
    },
    handleFreight(obj) {
      this.dialogfreight = false;

      this.yunfeiForm.id = obj.id;
      this.yunfeiForm.shipping_cost1 = obj.shipping_cost1;
      this.yunfeiForm.shipping_cost2 = obj.shipping_cost2;
      this.yunfeiForm.shipping_cost = obj.shipping_cost;

      orderSendShipping(this.yunfeiForm)
        .then((res) => {
          this.getData();
          this.$toast.success("Operation Succesful");
        })
        .catch((err) => {
          console.log("Frieght:  ", err);
          this.$toast.error("Frieght Error");
        });
    },
    handleFreightDialog(obj) {
      this.dialogfreight = true;
      this.obj = obj;
    },
    dateChange() {
      this.searchData.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchData.et = Array.isArray(this.dates) ? this.dates[1] : "";
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        if (value == null) {
          this.dates = [];

          this.searchData = newSearch();

          this.getData();
        }
      },
    },
  },
  watch: {
    shippingStatus: function (value) {
      this.searchData.status = value;

      this.getData();
    },
  },
};
</script>