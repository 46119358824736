import request from '@/utils/request'

// 订单列表
export function orderList(data) {
  return request({
    url: '/order/list',
    method: 'post',
    data: data
  })
}

// 订单删除
export function orderDelete(id) {
  return request({
    url: '/order/delete/' + id,
    method: 'delete'
  })
}

// 新家订单
export function orderAdd(data) {
  return request({
    url: '/order/upsert',
    method: 'post',
    data: data
  })
}

// 订单详情
export function orderInfo(id) {
  return request({
    url: '/order/info/' + id,
    method: 'get'
  })
}

// 订单审核
export function orderCheck(data) {
  return request({
    url: '/order/check',
    method: 'post',
    data: data
  })
}

// 确认收货
export function orderReceive(data) {
  return request({
    url: '/order/receive',
    method: 'post',
    data: data
  })
}

// QC
export function orderQC(data) {
  return request({
    url: '/order/qc',
    method: 'post',
    data: data
  })
}

// 财务确认
export function orderSendCheck(data) {
  return request({
    url: '/order/sendCheck',
    method: 'post',
    data: data
  })
}

// 物流
export function orderSend(data) {
  return request({
    url: '/order/send',
    method: 'post',
    data: data
  })
}

// po合同
export function orderPo(data) {
  return request({
    url: '/order/po',
    method: 'post',
    data: data
  })
}

// 订单驳回
export function orderReject(data) {
  return request({
    url: '/order/reject',
    method: 'post',
    data: data
  })
}

// 订单申请驳回
export function orderApplyReject(data) {
  return request({
    url: '/order/reject_apply',
    method: 'post',
    data: data
  })
}

export function receiptOrder(data) {
  return request({
    url: '/customer/receipt/order',
    method: 'post',
    data: data
  })
}
export function delReceiptOrder(id) {
  return request({
    url: '/order_receipt/delete/' + id,
    method: 'delete'
  })
}

export function desFileUpload(data) {
  return request({
    url: '/order/des_file',
    method: 'post',
    data: data
  })
}

export function orderLog(data) {
  return request({
    url: '/order/log',
    method: 'post',
    data: data
  })
}

export function orderPlace(data) {
  return request({
    url: '/order/place',
    method: 'post',
    data: data
  })
}

export function orderCancel(id) {
  return request({
    url: '/order/cancel' + id,
    method: 'get'
  })
}

export function orderProduct(data) {
  return request({
    url: '/order/product',
    method: 'post',
    data: data
  })
}

export function orderModifyInfo(data) {
  return request({
    url: '/order_modify/info',
    method: 'post',
    data: data
  })
}

export function orderModifyCheck(data) {
  return request({
    url: '/order_modify/check',
    method: 'post',
    data: data
  })
}

export function customerCheck(data) {
  return request({
    url: '/order/customerCheck',
    method: 'post',
    data: data
  })
}

export function customerOrder(data) {
  return request({
    url: '/order/customer_upsert',
    method: 'post',
    data: data
  })
}

export function affirmOrder(data) {
  return request({
    url: '/order/send',
    method: 'post',
    data: data
  })
}

export function upsertOrderSend(data) {
  return request({
    url: '/order_send/upsert',
    method: 'post',
    data: data
  })
}

export function listOrderSend(data) {
  return request({
    url: '/order_send/list',
    method: 'post',
    data: data
  })
}
export function delOrderSend(data) {
  return request({
    url: '/order_send/delete/' + data,
    method: 'delete'
  })
}

export function orderExport(data) {
  return request({
    url: '/order/export',
    method: 'post',
    data: data
  })
}

export function orderExports(data) {
  return request({
    url: '/order/export_jd',
    method: 'post',
    data: data
  })
}

export function orderInfoExports(data) {
  return request({
    url: '/order/info_export ',
    method: 'post',
    data: data
  })
}

export function customerAff(data, rt) {
  return request({
    url: '/order_send/receive/' + data + '?rt=' + rt,
    method: 'get'
  })
}

export function deliveExports(data) {
  return request({
    url: '/order_send/export',
    method: 'post',
    data: data
  })
}

export function mustImport(data) {
  return request({
    url: '/uuid_warehouse/must/import',
    method: 'post',
    data: data
  })
}

export function orderPdf(data) {
  return request({
    url: '/order_send/pdf',
    method: 'post',
    data: data
  })
}
// 订单投诉记录添加修改
export function orderComplaintUpsert(data) {
  return request({
    url: '/order_complaint/upsert',
    method: 'post',
    data: data
  })
}

// 投诉记录列表
export function orderComplaintList(data) {
  return request({
    url: '/order_complaint/list',
    method: 'post',
    data: data
  })
}

// 投诉记录删除
export function orderComplaintDel(id) {
  return request({
    url: '/order_complaint/delete/' + id,
    method: 'delete'
  })
}

// 投诉记录详情
export function orderComplaintInfo(id) {
  return request({
    url: '/order_complaint/info/' + id,
    method: 'get'
  })
}

// 设置发货订单运费
export function orderSendShipping(data) {
  return request({
    url: '/order_send/shipping',
    method: 'post',
    data: data
  })
}

// 采购
export function purchaseOrderStat(data) {
  return request({
    url: '/purchase_order/stat',
    method: 'post',
    data: data
  })
}

// 订单添加 新
export function orderUpsert(data) {
  return request({
    url: 'order/upsert',
    method: 'post',
    data: data
  })
}
